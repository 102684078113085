import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import SkincarePageComponent from "../../components/skin-care/Skincare";

const index = () => {
  return (
    <Layout>
      <Seo title="OMG Skin Care" />
      <SkincarePageComponent />
    </Layout>
  );
};

export default index;
